// event/home Hero background and text slider ////////////////////////////////////////////

export function eventHomeHeroBgSlider() {
  const heroFirstSlide = document.getElementById('hero-first-slide');
  const heroTextParent = document.getElementById('hero-text-parent');
  return {
    active: 1,
    init() {
      setInterval(() => {
        this.active = this.active === 3 ? 1 : this.active + 1;
      }, 6000);
    },

    // this function is used to set text container height, here we
    // read the height of the first slide and set that height for the contianer
    // so that height do not change for every slide.
    setcontentHeight() {
      this.active = 1; // go back to first slide for calculating the height
      setTimeout(() => {
        heroTextParent.style.minheight = `${heroFirstSlide.offsetHeight}px`;
        heroTextParent.style.height = `${heroFirstSlide.offsetHeight}px`;
      }, 600)
    }
  };
}


// event/home bottom slider ////////////////////////////////////////////

export function eventHomeBottomSlider() {
  const slider = document.getElementById("slider");
  const parent_slider = document.getElementById('slider-parent');
  const slides_label = document.querySelectorAll('#slider > div > div:last-child');
  const imageCount = 5;

  let imageWidth = (slider.offsetWidth < 1024) ? slider.offsetWidth / 2 : slider.offsetWidth / 4;
  let position = imageWidth / 2;
  let currentImage = 1;
  let center = 2;

  return {
    init() { setInterval(this.slideForward, 2000); },

    adjustParentSize() {
      const h = slider.offsetHeight, w = slider.offsetWidth;
      parent_slider.style.height = `${h + h / 3}px`;

      currentImage = 1;
      position = imageWidth / 2;
      center = (w < 1024) ? 2 : 3;
      imageWidth = (w < 1024) ? w / 2 : w / 4;

      slides_label.forEach(element => element.classList.add("hidden"));
      slides_label[center - 1].classList.toggle("hidden")
      slider.style.transform = `translateX(-${position}px)`;
    },

    slideForward() {
      // if the slide reach the last image then reset the slider without animation
      // to achieve a infinite slider effect
      if (currentImage === imageCount) {
        slides_label[currentImage + center - 2].classList.toggle("hidden");
        slides_label[center - 1].classList.toggle("hidden");
        position = imageWidth / 2;
        currentImage = 1;
        slider.style.transition = 'none';
        slider.style.transform = 'translateX(0)';
        setTimeout(() => {
          slider.style.transition = 'transform 0.5s';
          slider.style.transform = `translateX(-${position}px)`;
        }, 50);
      } else {
        currentImage++;
        slides_label[currentImage + center - 2].classList.toggle("hidden");
        slides_label[currentImage + center - 3].classList.toggle("hidden");
        position = imageWidth + position;
        slider.style.transform = `translateX(-${position}px)`;
      }
    },
  }
}


// event/company bottom slider ////////////////////////////////////////////

export function eventCompanyHeroBgSlider() {
  return {
    active: 1,
    init() {
      setInterval(() => {
        this.active = this.active === 3 ? 1 : this.active + 1;
      }, 4000);
    }
  };
}

export function eventAutoSlider() {
  const parent = document.getElementById("sec3-parent");
  const slideme = document.querySelectorAll(".slideme");
  const first_child = document.getElementById("first_child");
  let startX, endX;
  let intervalId;

  return {
    slide: 0,
    slides: [0, 1, 2], // Three slides in total
    init() {
      slideme.forEach(i => {
        i.addEventListener('touchstart', (e) => startX = e.touches[0].clientX);
        i.addEventListener('touchmove', (e) => endX = e.touches[0].clientX);
        i.addEventListener('touchend', () => {
          if (startX > endX + 50) if(this.slide != 2) this.slide += 1;
          if (startX < endX + 50) if(this.slide != 0) this.slide -= 1;
        });
      })

      this.start(4000);
    },
    start(x) {
      intervalId = setInterval(() => {
        this.slide = (this.slide + 1) % this.slides.length;
      }, x);
    },
    pause() { clearInterval(intervalId); },
    adjustParentSize() {
      parent.style.height = `${first_child.offsetHeight + 200}px`;
    }
  }
}

export function companySec2Slider() {
  return {
    bg: [
      "text-white bg-[url('../images/event/company_section_2_1.webp')]",
      "text-white bg-[url('../images/event/company_section_2_2.webp')]",
      "text-black bg-[url('../images/event/company_section_2_3.webp')]",
    ],
    text: [
      "Events And Entertainment",
      "Branding",
      "Wedding Planner",
    ],
    active: 0,
    init() {
      setInterval(() => {
        this.active = (this.active + 1) % 3;
      }, 3000)
    }
  }
}

export function brandingSec2Slider() {
  const slider = document.getElementById("slider");
  const parent_slider = document.getElementById('slider-parent');
  const imageCount = 8;
  let currentImage = 0;
  let startX, endX;

  const resetSlider = (value) => {
    setTimeout(() => {
      slider.style.transition = 'transform 0.5s';
      currentImage = value;
      slider.style.transform = `translateX(-${currentImage * imageWidth}px)`;
    }, 0);
  }

  const setImageWidth = () => {
    let sliderWidth = slider.offsetWidth;
    if (sliderWidth < 640) {
      return sliderWidth;
    } else if (sliderWidth < 768) {
      return sliderWidth / 2;
    } else {
      return sliderWidth / 3;
    }
  }

  let imageWidth = setImageWidth();

  let intervalId;
  return {
    init() {
      parent_slider.addEventListener('touchstart', (e) => startX = e.touches[0].clientX);
      parent_slider.addEventListener('touchmove', (e) => endX = e.touches[0].clientX);
      parent_slider.addEventListener('touchend', () => {
        if (startX > endX + 50) this.slideForward()
        if (startX < endX + 50) this.slideBackward()
      });

      this.start();
    },
    start() { intervalId = setInterval(this.slideForward, 2000); },
    pause() { clearInterval(intervalId); },

    adjustParentSize() {
      parent_slider.style.height = `${slider.offsetHeight}px`;
      imageWidth = setImageWidth();
      currentImage = 0
      slider.style.transform = `translateX(-${currentImage * imageWidth}px)`;
    },

    slideForward() {
      currentImage++;
      if (currentImage === imageCount + 1) { // If we've reached the cloned images Reset position to the start without animation
        slider.style.transition = 'none';
        slider.style.transform = 'translateX(0)';
        resetSlider(1);
      } else {
        slider.style.transform = `translateX(-${currentImage * imageWidth}px)`;
      }
    },

    slideBackward() {
      currentImage--;
      if (currentImage + 1 === 0) { // If we've reached the cloned images Reset position to the start without animation
        slider.style.transition = 'none';
        slider.style.transform = `translateX(-${imageCount * imageWidth}px)`;
        resetSlider(imageCount - 1);
      } else {
        slider.style.transform = `translateX(-${currentImage * imageWidth}px)`;
      }
    },
  }
}

export function brandingSec3slider() {
  const sec3sliderParent = document.getElementById('sec3-slider-parent');
  const sec3sliderChild = document.querySelectorAll('#sec3-slider-parent > :not(:first-child)');
  const sec3sliderChildimg = sec3sliderParent.querySelectorAll(':not(:first-child) > img');
  const sec3sliderfFirstChild = document.getElementById('sec3-slide-first-child');
  let i = sec3sliderChild.length - 1;

  function resetSec3Slider() {
    sec3sliderChild.forEach(child => {
      child.style.transition = 'none';
      child.style.opacity = '1';
    });

    sec3sliderChildimg.forEach(child => child.style.transition = 'none');

    for (let j = 0; j <= 7; j++) {
      sec3sliderChild[j].style.transform = `translateX(34vw)`;
      sec3sliderChildimg[j].style.transform = 'scale(0.6)';
      sec3sliderChildimg[j].style.boxShadow = `none`
      sec3sliderChildimg[j].style.filter = `blur(1.5px)`
    }

    sec3sliderChild[8].style.transform = `translateX(26vw)`;
    sec3sliderChildimg[8].style.transform = 'scale(0.7)';
    sec3sliderChildimg[8].style.boxShadow = `rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px`
    sec3sliderChildimg[8].style.filter = `blur(1.5px)`

    sec3sliderChild[9].style.transform = `translateX(18vw)`;
    sec3sliderChildimg[9].style.transform = 'scale(0.8)';
    sec3sliderChildimg[9].style.boxShadow = `rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px`
    sec3sliderChildimg[9].style.filter = `blur(1.5px)`

    sec3sliderChild[10].style.transform = `translateX(10vw)`;
    sec3sliderChildimg[10].style.transform = 'scale(0.9)';
    sec3sliderChildimg[10].style.boxShadow = `rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px`
    sec3sliderChildimg[10].style.filter = `blur(1.5px)`

    sec3sliderChild[11].style.transform = `translateX(0vw)`;
    sec3sliderChildimg[11].style.transform = 'scale(1)';
    sec3sliderChildimg[11].style.boxShadow = `rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px`
    sec3sliderChildimg[11].style.filter = `none`

    setTimeout(() => {
      sec3sliderChild.forEach(child => child.style.transition = `transform 0.5s, opacity 0.5s`);
      sec3sliderChildimg.forEach(child => child.style.transition = 'transform 0.5s');
      i = sec3sliderChild.length - 1;
      nextChild();
    }, 0);
  }

  function nextChild() {
    sec3sliderChild[i].style.transform = `translateX(-200%)`
    sec3sliderChild[i].style.opacity = `0`

    sec3sliderChild[i - 1].style.transform = `translateX(0)`
    sec3sliderChildimg[i - 1].style.transform = 'scale(1)';

    sec3sliderChild[i - 2].style.transform = `translateX(10vw)`
    sec3sliderChildimg[i - 2].style.transform = 'scale(0.9)';

    sec3sliderChild[i - 3].style.transform = `translateX(18vw)`
    sec3sliderChildimg[i - 3].style.transform = 'scale(0.8)';

    sec3sliderChild[i - 4].style.transform = `translateX(26vw)`
    sec3sliderChildimg[i - 4].style.transform = 'scale(0.7)';

    sec3sliderChild[i - 5].style.transform = `translateX(34vw)`
    sec3sliderChildimg[i - 5].style.transform = 'scale(0.6)';

    for (let j = 1; j <= 5; j++) {
      sec3sliderChildimg[i - j].style.boxShadow = `rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px`
      if (j == 1) sec3sliderChildimg[i - j].style.filter = `none`;
    }
    --i;
  }

  let startX;
  let endX;
  let intervalId;

  return {
    init() {
      sec3sliderParent.addEventListener('touchstart', (e) => startX = e.touches[0].clientX);
      sec3sliderParent.addEventListener('touchmove', (e) => endX = e.touches[0].clientX);
      sec3sliderParent.addEventListener('touchend', () => { if (startX > endX + 50) this.changeSlide() });

      this.start();
    },
    start() { intervalId = setInterval(this.changeSlide, 4000) },
    pause() { clearInterval(intervalId) },
    sec3SetHeight() {
      sec3sliderParent.style.height = `${sec3sliderfFirstChild.offsetHeight + 150}px`;
    },
    changeSlide() {
      if (i == 4) resetSec3Slider();
      else nextChild();
    },
  }
}
