// home page background slider /////////////////////////////////////////////////////////////////////

export function homeSlider() {
  let intervalId;
  return {
    event: false,
    isMobile: false,
    active: false,
    init() {
      console.log("init");
      this.active = false;
      if (window.innerWidth < 768) {
        this.isMobile = true;
        this.start();
      } else {
        this.isMobile = false;
      }
    },
    start() {
      console.log("start");
      intervalId = setInterval(() => {
        this.active = !this.active;
      }, 3000);
    },
    stop() {
      console.log("stop");
      clearInterval(intervalId);
    },
    eventHover() {
      if (this.isMobile == false) {
        this.event = true;
        this.active = true;
        console.log(this.active);
      }
    },
    weddingHover() {
      if (this.isMobile == false) {
        this.event = false;
        this.active = false;
        console.log(this.active);
      }
    }
  }
}
