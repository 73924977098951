/////// wedding/services slider javscript code ////////////////////////////////////////////
export function weddingServicesSlider() {
  const slider = document.getElementById("slider");
  const parent_slider = document.getElementById('slider-parent');
  const imageCount = 16;
  let startX, endX;
  let currentImage = 0;

  const setImageWidth = () => {
    let sliderWidth = slider.offsetWidth;
    if (sliderWidth < 768) return sliderWidth / 2;
    else if (sliderWidth < 1024) return sliderWidth / 3;
    else return sliderWidth / 4;
  }

  let imageWidth = setImageWidth();

  const resetSlider = (value) => {
    setTimeout(() => {
      slider.style.transition = 'transform 0.5s';
      currentImage = value;
      slider.style.transform = `translateX(-${currentImage * imageWidth}px)`;
    }, 0);
  }

  let intervalId;

  return {
    init() {
      parent_slider.addEventListener('touchstart', (e) => startX = e.touches[0].clientX);
      parent_slider.addEventListener('touchmove', (e) => endX = e.touches[0].clientX);
      parent_slider.addEventListener('touchend', () => {
        if (startX > endX + 50) this.slideForward()
        if (startX < endX + 50) this.slideBackward()
      });


      this.adjustParentSize();
      this.start();
    },

    start() { intervalId = setInterval(this.slideForward, 3000); },
    pause() { clearInterval(intervalId); },

    adjustParentSize() {
      parent_slider.style.height = `${slider.offsetHeight}px`;
      imageWidth = setImageWidth();
      currentImage = 0
      slider.style.transform = `translateX(-${currentImage * imageWidth}px)`;
    },

    slideForward() {
      currentImage++;
      if (currentImage === imageCount + 1) { // If we've reached the cloned images Reset position to the start without animation
        slider.style.transition = 'none';
        slider.style.transform = 'translateX(0)';
        resetSlider(1);
      } else {
        slider.style.transform = `translateX(-${currentImage * imageWidth}px)`;
      }
    },

    slideBackward() {
      currentImage--;
      if (currentImage + 1 === 0) { // If we've reached the cloned images Reset position to the start without animation
        slider.style.transition = 'none';
        slider.style.transform = `translateX(-${imageCount * imageWidth}px)`;
        resetSlider(imageCount - 1);
      } else {
        slider.style.transform = `translateX(-${currentImage * imageWidth}px)`;
      }
    },
  }
}

