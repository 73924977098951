export function eventContactFormControler() {
  return {
    form: {
      name: '',
      email: '',
      phoneno: '',
      interestedin: '',
      message: '',
    },
    submitted: false,

    submitForm() {
      this.submitted = true;
      fetch('http://localhost/api/submit_event_contactform', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(this.form)
      }).then(response => {
        this.submitted = false;
        if (response.statusText == "success") {
          // document.getElementById('event-contact-form').reset();
          Object.assign(this, eventContactFormControler());
          alert("Form Submitted Successfully")
        } else {
          alert("Oops, Something went wrong, try again!")
        }
      }).catch(error => {
        alert("oops, Something went wrong, try again!")
        console.error('Error:', error)
      });
    }
  }
}

export function weddingContactFormControler() {
  return {
    form: {
      name: '',
      email: '',
      phoneno: '',
      needed_service: '',
      message: '',
    },
    submitted: false,

    submitForm() {
      this.submitted = true;
      fetch('http://localhost/api/submit_wedding_contactform', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(this.form)
      }).then(response => {
        this.submitted = false;
        if (response.statusText == "success") {
          // document.getElementById('wedding-contact-form').reset();
          Object.assign(this, weddingContactFormControler());
          alert("Form Submitted Successfully")
        } else {
          alert("Oops, Something went wrong, try again!")
        }
      }).catch(error => {
        alert("oops, Something went wrong, try again!")
        console.error('Error:', error)
      });
    }
  }
}

export function checklistFormControler() {
  return {
    checklistForm: {
      NameOfCouple: '',
      VenueAndNoofGuest: '',
      ContactNo: '',
      EmailID: '',
      DateOfEvent: '',
      TypeOfFunction: '',
      Caste: '',
      BudgetEstimate: '',
      services: {
        VenueDecor: { selection: [], details: '' },
        EntertainmentProgram: { selection: [], details: '' },
        GuestManagement: { selection: [], details: '' },
        Lighting: { selection: [], details: '' },
        OtherElements: { selection: [], details: '' },
        IfChristianWedding: { selection: [], details: '' },
      }
    },
    submitted: false,

    submitForm() {
      this.submitted = true;
      fetch('http://localhost/api/submit_checklist', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(this.checklistForm)
      }).then(response => {
        this.submitted = false;
        if (response.statusText == "success") {
          Object.assign(this, checklistFormControler());
          alert("Form Submitted Successfully");
        } else {
          alert("Oops, Something went wrong, try again!");
        }
      }).catch(error => {
        alert("oops, Something went wrong, try again!");
        console.error('Error:', error);
      });
    },
  }
}
