import Alpine from "alpinejs";
import * as wedding from "./wedding.js";
import * as event from "./event.js";
import * as form from "./forms.js";
import * as home from "./home.js";

Alpine.data("homeBgSlider", home.homeSlider);

Alpine.data("weddingServicesSlider", wedding.weddingServicesSlider);

Alpine.data("autoSlider", event.eventAutoSlider);
Alpine.data("companySec2Slider", event.companySec2Slider);
Alpine.data("brandingSec2Slider", event.brandingSec2Slider);
Alpine.data("brandingSec3slider", event.brandingSec3slider);
Alpine.data("eventHomeBottomSlider", event.eventHomeBottomSlider);
Alpine.data("eventHomeHeroBgSlider", event.eventHomeHeroBgSlider);
Alpine.data("eventCompanyHeroBgSlider", event.eventCompanyHeroBgSlider);

Alpine.data("checklistFormControler", form.checklistFormControler);
Alpine.data("eventContactFormControler", form.eventContactFormControler);
Alpine.data("weddingContactFormControler", form.weddingContactFormControler);

window.Alpine = Alpine
Alpine.start()
